import React from 'react';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { layoutOptions } from "../components/Content/RichText";
import { Helmet } from "react-helmet";
import Img from "gatsby-image/withIEPolyfill"
import * as Markdown from 'react-markdown';
import Layout from "../components/layout";
import moment from 'moment';
import style from "../components/Feed/News.module.css";

const BlogPostContent = ({ data, location }) => {
    const {
        slug,
        author,
        metaKeywords,
        publishDate,
        title,
        copy,
        heroImage,
        heroVideo } = data.contentfulBlogPost;
    const content = data.contentfulBlogPost;
    const richText = documentToReactComponents(content.content.json, layoutOptions);
    const titleBlog = `${title}`;
    const url = `https://www.soleraadvisors/feed/${slug}`
    const description = copy.copy
    const videoId = heroVideo ? heroVideo.split('v=')[1].split('&')[0] : null;
    const metaImage = videoId ? `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg` : `https:${heroImage.resize.src}`;
    const videoSource = videoId ? `https://www.youtube.com/embed/${videoId}?cc_load_policy=1&color=white&${heroVideo.split('&')[1]}` : null;

    return (
        <Layout location={location}>
            <Helmet>
                <title>{titleBlog}</title>
                <meta name="image" content={metaImage} />
                <meta name="url" content={url} />
                <meta name="description" content={description} />
                <meta name="keywords" content={metaKeywords} />

                <meta property="og:title" content={titleBlog} />
                <meta property="og:image" content={metaImage} />
                <meta property="og:url" content={url} />
                <meta property="og:description" content={description} />

                <meta name="twitter:title" content={titleBlog} />
                <meta name="twitter:image" content={metaImage} />
                <meta name="twitter:url" content={url} />
                <meta name="twitter:description" content={description} />
                <link rel="canonical" href={url} />
            </Helmet>

            <section className="uk-section uk-section-default uk-padding-remove-bottom">
                <div className="uk-container">
                    <div className="uk-container uk-container-xsmall">
                        <div className="uk-margin-xlarge-top">
                            <h2 className={`h2__large ${style.postHeader} uk-text-primary uk-margin-remove-adjacent uk-margin-remove-top`}>
                                {title}
                            </h2>
                            <p className="uk-text-large uk-margin-medium">{description}</p>
                            <p className="uk-text-muted uk-text-meta">Posted on {moment(publishDate).format('MMM D, YYYY')} by {author}</p>
                        </div>
                    </div>
                </div>
            </section>

            {heroVideo ?
                <section className={`uk-section`}>
                    <div className={`uk-container uk-container-xlarge`}>
                        <div className={`uk-container`}>
                            <div style={{ position: "relative", paddingBottom: "56.25%", height: "0", overflow: "hidden", zIndex: "5" }} className="uk-box-shadow-large">
                                <iframe
                                    style={{ position: "absolute", top: "0", left: "0", height: "100%", width: "100%" }}
                                    src={videoSource}
                                    frameBorder="0"
                                    title="YouTube video"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                :
                <section className={`uk-section`}>
                    <div className={`uk-container uk-container-large`}>
                        <Img
                            fluid={heroImage.localFile.childImageSharp.fluid}
                            alt={heroImage.title}
                            className={`uk-border-rounded`}
                            objectFit="cover"
                            objectPosition="50% 50%"
                        />
                    </div>
                </section>
            }

            <div className="uk-container">
                <div className={`${style.postContent}`}>
                    {richText}
                </div>
            </div>
        </Layout>
    );
};

export default BlogPostContent;

export const newsPostQuery = graphql`
    query newsPostQuery($slug: String) {
        contentfulBlogPost(slug: {
            eq: $slug
        }) {
            author
            topic
            metaKeywords
            publishDate
            title
            copy {
                copy
            }
            content {
                json
            }
            heroImage {
                resize(width: 1600, quality: 100) {
                    src
                }
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 1600, quality: 80) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
            heroVideo
            slug
        }
    }`
